import React from "react"
import HeadingL from "../../components/shared/HeadingL"
import Layout from "../../components/do/Layout"
import SEO from "../../components/Seo"
import { PAGE } from "../../constants"
import AssessmentPage from "../../components/do/AssessmentPage"

const assessment = () => (
  <Layout>
    <SEO title={PAGE.title.assessment} siteTitle="do" />
    <HeadingL title={PAGE.title.assessment} target="do" />
    <AssessmentPage />
  </Layout>
)

export default assessment
